// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement, Fragment } from 'react';
import { Button } from '@plesk/ui-library';
import { NotificationPropType } from './lib/PropTypes';

const NotificationCenterView = ({
    notification: { code, message, actionLink, actionText, openInNewTab },
}) => (
    <Fragment>
        <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: message }}
        />
        {actionLink ? (
            <div className="notification-center-view__actions">
                <Button
                    data-action="NOTIFICATION_CTA"
                    data-type={code}
                    component="a"
                    intent="primary"
                    size="lg"
                    href={actionLink}
                    target={openInNewTab ? '_blank' : null}
                    rel={openInNewTab ? 'noopener noreferrer' : null}
                >
                    {actionText}
                </Button>
            </div>
        ) : null}
    </Fragment>
);

NotificationCenterView.propTypes = {
    notification: NotificationPropType.isRequired,
};

export default NotificationCenterView;
