// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import { Switch } from 'react-router-dom';
import Route from './RouteRequire';
import routes from '../routes';

const Routes = () => (
    <Switch>
        {routes.map(({ path, ...props }) => (
            <Route key={path} path={path} {...props} />
        ))}
    </Switch>
);

export default Routes;
