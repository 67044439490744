// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { Form as BaseForm } from '@plesk/ui-library';
import { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { redirect, redirectPost, api, showInternalError } from 'jsw';
import { toFormData } from 'helpers/form';

class Form extends Component {
    state = {
        errors: {},
        state: null,
    };

    handleSubmit = (values, isApply) => {
        Promise.resolve(typeof this.props.onSubmit === 'function' ? this.props.onSubmit(values) : true)
            .then(result => {
                if (result) {
                    this.setState({
                        state: isApply ? 'apply' : 'submit',
                    });

                    api.post(this.props.action || window.location.href, toFormData(values))
                        .then(this.handleSubmitSuccess)
                        .catch(showInternalError)
                        .finally(() => this.setState({ state: null }));
                }
            });
    };

    handleSubmitSuccess = ({ redirect: url, postData, target, formMessages }) => {
        if (url) {
            if (this.state.state === 'apply') {
                document.location.reload();
            } else if (postData) {
                redirectPost(url, postData, target);
            } else {
                redirect(url, null, target);
            }
        } else {
            this.setState({
                errors: formMessages,
            });
        }
    };

    render() {
        return (
            <BaseForm
                {...this.props}
                onSubmit={this.handleSubmit}
                errors={this.state.errors}
                state={this.state.state}
            />
        );
    }
}

Form.propTypes = {
    action: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
};

Form.defaultProps = {
    onSubmit: null,
};

export default Form;
