// Copyright 1999-2021. Plesk International GmbH. All rights reserved.
/* eslint-disable react/jsx-max-depth */

import { createElement, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { api, prepareUrl, createComponent, Locale } from 'jsw';
import JswComponent from './JswComponent';

const Status = ({ messages }) => {
    const [currentMessages, setMessages] = useState(messages);

    const handleCloseMessage = message => {
        if (message.hideUrl) {
            api.post(prepareUrl(message.hideUrl));
        }
        setMessages(currentMessages.filter((_, key) => message.key !== key));
    };

    const renderMessage = message => {
        const {
            componentType,
            status,
            content,
            title = Locale.getSection('components.status').lmsg(status),
            class: className,
            ...other
        } = message;

        if (componentType) {
            return (
                <JswComponent>
                    {() => createComponent({
                        componentType,
                        title,
                        type: status,
                        message: content,
                        ...other,
                    })}
                </JswComponent>
            );
        }

        const {
            key,
            source,
            hideUrl,
        } = message;

        return (
            <div
                key={key}
                className={classNames('msg-box', `msg-${status}`, className)}
                data-source={source}
            >
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div className="msg-content">
                                        {hideUrl ? <span className="close" onClick={() => handleCloseMessage(message)} /> : null}
                                        {title ? (
                                            <span className="title">
                                                {title}{': '}
                                            </span>
                                        ) : null}
                                        <span
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{ __html: content }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return currentMessages.map((message, key) => renderMessage({ key, ...message }));
};

Status.propTypes = {
    messages: PropTypes.array.isRequired,
};

export default Status;
