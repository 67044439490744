// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import UAT from 'common/user-activity-tracking';
import addUrlParams from './addUrlParams';
import emptyFn from './emptyFn';

class ApiFetchError extends Error {
    constructor(...args) {
        super(...args);
        this.name = 'ApiFetchError';
    }
}

const request = (url, config = {}) => {
    if (window.Ajax) {
        window.Ajax.activeRequestCount++;
    }

    const headers = {
        'X-Requested-With': 'XMLHttpRequest',
    };
    if (!fetch.polyfill) {
        const tokenEl = document.getElementById('forgery_protection_token');
        if (tokenEl) {
            headers['X-Forgery-Protection-Token'] = tokenEl.content;
        }
    }

    config = {
        credentials: 'same-origin',
        ...config,
        headers: {
            ...headers,
            ...config.headers,
        },
    };

    return fetch(url, config)
        .then(response => {
            if (response.status === 400) {
                window.location.reload();
                return new Promise(emptyFn);
            }
            return response.json();
        })
        .catch(error => {
            if (error instanceof TypeError) {
                throw new ApiFetchError(error.message);
            }
            throw error;
        })
        .finally(() => {
            if (window.Ajax) {
                window.Ajax.activeRequestCount--;
            }
        });
};

export default {
    get: (url, params = {}) => request(addUrlParams(url, params), { method: 'GET' }),
    post: (url, params = {}) => {
        UAT.dispatchAction('REQUEST', { url });
        const headers = {};
        let body;
        if (params instanceof FormData) {
            body = params;
        } else {
            body = JSON.stringify(params);
            headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        return request(url, {
            method: 'POST',
            headers,
            body,
        });
    },
};
